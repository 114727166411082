body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #888888;
}

::-webkit-scrollbar{
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb{
  background: #cccccc;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover{
  background: #ffffff;
}

::-webkit-scrollbar-track{
  border-radius: 0px;
  box-shadow: inset -10px -10px 0px -30px #F0F0F0;
}



@media screen and (min-width: 0px) {
  .article .row,
  .article .row-reverse,
  .article .column {
    display: flex;
    flex-direction: column;
  }
  .article h2 {
    margin: 0px 35px 20px 35px;
  }
  .article p {
    margin: 0px 35px 20px 35px;
  }
  .article img {
    width: calc(100vw - 90px);
    margin: 0px 35px 20px 35px;
  }
  .swiper-button-prev{
    margin-left: 10vw;
  }
  .swiper-button-next {
    margin-right: 10vw;
  }
  .article-viewpoint p {
    margin-left: 0;
    margin-right: 0;
  }
  .article-viewpoint h2 {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 900px) {
  .article .row {
    display: flex;
    flex-direction: row;
  }
  .article .row-reverse {
    display: flex;
    flex-direction: row-reverse;
  }
  .article .column {
    display: flex;
    flex-direction: column;
  }
  .article p {
    margin-bottom: 2em;
  }
  .article img {
    max-height: 40vh!important;
    max-width: 40vw!important;
    object-fit: contain;
  }
  .swiper-button-prev{
    margin-left: 1.8vw;
  }
  .swiper-button-next {
    margin-right: 1.8vw;
  }

  .article-viewpoint p {
    margin-bottom: 2.5em;
  }

}
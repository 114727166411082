@media screen and (min-width: 0px) {
  :root {
    --swiper-navigation-size: 16px;
  }
}

@media screen and (min-width: 900px) {
  :root {
    --swiper-navigation-size: 31.1px;
  }
}

/*
  --swiper-navigation-color: var(--swiper-theme-color);
  */


.swiper-slide {
  width: auto;
}

.slide-image {
  width: auto;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 8px;
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
}